// import { dev } from "$app/environment";
import * as Sentry from '@sentry/sveltekit';

// Get the app's environment string from vercel
// const env = dev ? 'development' : 'production';
const env = import.meta.env.VITE_VERCEL_ENV || 'development';

Sentry.init({
  dsn: 'https://c796d116d2637538f8ecab0dae43d08f@o4507184734339072.ingest.de.sentry.io/4507184738074704',
  tracesSampleRate: 1.0,
  environment: env,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  skipOpenTelemetrySetup: true,
  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
    // Sentry.vercelAIIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      autoinject: true,
      triggerLabel: "",
      showName: true,
      formTitle: "Feedback",
      submitButtonLabel: "Submit",
      showEmail: true,
      useSentryUser: {
        name: "id",
        email: "email",
      },
      messagePlaceholder: "How can we help?",
    }),
  ],

  
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
